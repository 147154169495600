.container {
  width: 50%;
  height: 85vh;
  margin: auto;
}

#background {
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
  height: 100vh;
}

nav {
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;
  padding-bottom: 1rem;
  margin-right: 10rem;
}
.nav-item {
  font-size: 1.25rem;
  text-decoration: none;
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  margin-right: 2em;
}
.image-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.image {
  height: 30rem;
}
p {
  font-family: "Quicksand", sans-serif;
  color: #fff;
}
.title {
  font-size: 3rem;
  text-align: center;
  font-weight: 500;
  @media only screen and (max-width: 320px) {
    font-size: 1em;
  }
}
.sub-title {
  font-size: 2rem;
  text-align: center;
  font-style: italic;
}
.description-title {
  font-size: 1.5rem;
  font-weight: 600;
}
.description {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 95%;
  }
  nav {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    padding-top: 0;
  }
  .nav-item {
    font-size: 1rem;
    font-weight: 600;
    margin-right: 0rem;
  }
  .image {
    height: 20rem;
  }
  .title {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
  .sub-title {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .description-title {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  .description {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .container {
    width: 75%;
  }
  nav {
    justify-content: center;
    margin: 1rem;
    padding-top: 0;
  }
}
